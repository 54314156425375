<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/yl-operation-log"
    query="&sort=-id"
    entity-name="日志"
    add-btn=""
    hide-action
    :columns="columns"
    :custom-url="customUrl"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-range-picker
          v-model="form.time"
          :show-time="{ format: 'HH:mm' }"
          :placeholder="['开始时间', '结束时间']"
          format="YYYY-MM-DD HH:mm"
          value-format="YYYY-MM-DD HH:mm"
          @change="updateList"
          class="range-picker"
        />
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
    import {getKeyTitle} from "../../common/js/tool";

    const logTypeList = [
        { key: 1, title: '管理平台登录' },
        { key: 2, title: '小程序登录' },
    ]

    export default {
        name: "OperateLog",
        data() {
            return {
                columns: [
                    { title: "操作时间", dataIndex: "create_time" },
                    { title: "操作人", dataIndex: "wx_user_id", customRender: text => <open-data type="userName" openid={text} /> },
                    { title: "操作类型", dataIndex: "typeText", customRender: () => '登陆' },
                    { title: "操作行为", dataIndex: "type", customRender: text => getKeyTitle(logTypeList, text) },
                    { title: "详细信息", dataIndex: "address", customRender: (text, record) => <div>登录地点：{text}<br/>登录IP：{record.ip}</div> },
                ],
            };
        },

        methods: {
            updateList() {
                this.$refs.dataList.getList();
            },
            customUrl(form) {
                let res = "";
                if (form.time && form.time.length === 2) {
                    const [start, end] = form.time;
                    res = `&filter[create_time][gte]=${start}&filter[create_time][lt]=${end}`
                }
                return res;
            }
        },
    };
</script>

<style lang="less" scoped></style>
